import {
  getCommonParamsOptions,
  getGlobalParamsOptionsAsync
} from '@/utils/common'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.template-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'appId',
      type: 'select',
      label: 'common.app-application',
      isResolveGlobalParams: true,
      initialValue: '',
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('application', { isNeedPreFix: false }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500,
        disabled: true
      }
    }
  ],
  validateRules: {}
}
