import { getGlobalParamsOptionsAsync } from '@/utils/common'

export const baseUpdateFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.template-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'appId',
      type: 'select',
      label: 'common.app-application',
      isResolveGlobalParams: true,
      initialValue: '',
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('application', { isNeedPreFix: false })
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    appId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
