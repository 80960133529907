<template>
  <div class="tb-container2" ref="tbContainerRef">
    <!-- 左边的 el-select 元素 -->

    <!-- 右边的元素，使用 flex 容器来包裹它们 -->
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <!-- 条件渲染的 svg-icon -->

      <!-- 条件渲染的 el-input -->
      <el-input
        v-if="!isViewPage"
        v-model="inputValue"
        class="w-50 m-2"
        placeholder="Search Parameter"
        style="width: 20%"
        ><i><Search /></i
      ></el-input>
    </div>
    <!-- 表格 -->
    <el-table
      ref="tableRef"
      :data="
        parameterList.filter(
          (data) =>
            !inputValue ||
            data.key.includes(inputValue) ||
            data.key.toLowerCase().includes(inputValue.toLowerCase())
        )
      "
      style="width: 100%; margin-top: 5px"
      @selection-change="handleSelectionChange"
      row-key="id"
      max-height="800"
      border
      stripe
    >
      <el-table-column
        type="selection"
        width="55"
        :reserve-selection="true"
        v-if="templateType && !isViewPage"
      />
      <template v-if="templateType === '00'">
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="key" label="Parameter" width="360" />
        <el-table-column prop="type" label="Value Type" width="160">
          <template #default="{ row }">
            {{ row.type + ' - ' + valueTypeMap[row.type] }}
          </template>
        </el-table-column>
        <el-table-column prop="length" label="Value Length" width="160">
          <template #default="{ row }">
            {{
              ['00', '01', '03'].includes(row.type) ? row?.value?.length : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="value" label="Value">
          <template #default="{ row }">
            <!-- 区分templateType 区分类型 -->
            <!--字符串 String-->
            <template v-if="templateType === '00'">
              <!-- 字符串转成数字类型 -->
              <div v-if="!row.isEditable">
                <span>{{ row?.value }}</span>
                <i
                  v-if="!isViewPage"
                  style="color: #1a6eb6; cursor: pointer"
                  class="el-icon-edit-outline"
                  @click="handleEdit(row)"
                ></i>
              </div>
              <el-form
                :model="row"
                :ref="setFormRef(row)"
                inline-message
                v-if="!isViewPage"
              >
                <el-form-item prop="value" :rules="getCurrentRule(row.type)">
                  <el-input
                    style="width: 65%"
                    ref="inputRef"
                    v-show="row.isEditable"
                    v-model="row.value"
                    @blur="row.isEditable = false"
                    :disabled="row.disabled"
                    maxlength="255"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </template>
            <!--Boolean-->
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  defineExpose,
  nextTick,
  watch,
  reactive
} from 'vue'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons'
import { useCommonParam } from '@/hooks/use-common-params.js'
import { cloneDeep } from 'lodash'
const props = defineProps({
  templateType: {
    type: String,
    default: '00' // Parameter
  },
  selectedItems: {
    type: Array,
    default: () => []
  },
  isViewPage: {
    type: Boolean,
    default: false
  },
  selectedParameterData: {
    type: Array,
    default: () => []
  },
  initialSelectedParameterRows: {
    type: Array,
    default: () => []
  }
})

const tableRef = ref(null)
const store = useStore()
const parameterList = ref([])
const selectedRowItems = ref([])
// const selectedRowKeys = ref([])
const getCurrentRule = (type) => {
  if (type === '01') {
    return [
      {
        message: 'Please input an positive integer value',
        trigger: 'blur',
        // 假设你的校验库支持这种类型检查
        pattern: /^[+]?\d+$/ // 正则表达式，用于匹配整数
      }
    ]
  } else if (type === '02') {
    return [
      {
        pattern: /^(true|false|True|False|TRUE|FALSE)$/,
        message: 'Please input True or False',
        trigger: 'blur'
      }
    ]
  } else if (type === '00') {
    return []
  }
}

const inputRef = ref(null)
const handleEdit = (row) => {
  const updatedParameter = parameterList.value.find(
    (item) => item.id === row.id
  )
  if (updatedParameter) {
    updatedParameter.isEditable = true
  }
  nextTick(() => {
    inputRef.value.focus()
  })
}

const formRef = ref(null)

const getValidateRes = () => {
  formRef.value.validate((valid) => {
    return valid
  })
}

const formRefs = reactive({}) // 用于存储表单引用的响应式对象

function setFormRef(row) {
  return (el) => {
    if (el) {
      formRefs[row.id] = el // 假设每行都有一个唯一的 id
    }
  }
}

async function validateAllForms() {
  const promises = []
  let result = false
  Object.keys(formRefs).forEach((id) => {
    // 使用 Object.prototype.hasOwnProperty 的正确方式
    if (Object.prototype.hasOwnProperty.call(formRefs, id)) {
      const form = formRefs[id]
      if (form && typeof form.validate === 'function') {
        promises.push(
          form.validate().catch((error) => {
            console.log(error)
            // 处理验证失败的情况（可选）
            result = false
            console.error(`Validation failed for form with id ${id}:`, error)
            return false // 或者其他适当的值来表示验证失败
          })
        )
      }
    }
  })
  try {
    const results = await Promise.all(promises)
    result = results.every((item) => item === true)
    console.log('All forms are valid', results)
  } catch (error) {
    console.error('Error validating forms', error)
  }
  return result
}

const handleSelectionChange = (val) => {
  selectedRowItems.value = val
  // 重置选中状态
  listData.value = parameterList.value.map((item) => ({
    ...item,
    checked: val.some((selectedItem) => selectedItem.id === item.id)
  }))
}

watch(
  () => selectedRowItems.value.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > 0) {
      listData.value = listData.value.map((item) => ({
        ...item,
        checked: false
      }))
    }
  }
)

const inputValue = ref('')
watch(
  () => inputValue.value,
  async (newVal, oldVal) => {
    if (newVal === '' && oldVal !== '') {
      // 保存当前选中的行
      const checkedItems = listData.value
        ?.filter((item) => item.checked)
        ?.map((item) => item.id)

      nextTick(() => {
        for (let i = 0; i < parameterList.value.length; i++) {
          if (checkedItems.includes(parameterList.value[i].id)) {
            tableRef.value.toggleRowSelection(parameterList.value[i], true)
          }
        }
      })
    }
  }
)

const clearSelection = () => {
  tableRef.value.clearSelection()
}

watch(
  () => props.selectedItems,
  (newVal) => {
    if (newVal) {
      console.log(newVal)
      if (!props.isViewPage) {
        loadCheckedItems()
      } else {
        parameterList.value = parameterList.value.filter((item) => {
          console.log(item.id, newVal)
          return newVal.includes(String(item.id))
        })
        console.log(parameterList.value)
      }
    }
  }
)

watch(
  () => props.selectedParameterData,
  (newVal) => {
    if (newVal && newVal.length > 0) {
      // 回显相关选中参数中的值
      console.log('====', newVal)
      const map = {}
      newVal.forEach((item) => {
        map[item.id] = item.value
      })
      const selectedIds = newVal.map((item) => item.id)
      parameterList.value.forEach((item) => {
        if (selectedIds.includes(String(item.id))) {
          item.value = map[item.id]
        }
      })
      initialParameterList.value.forEach((item) => {
        if (selectedIds.includes(String(item.id))) {
          item.value = map[item.id]
        }
      })
      listData.value.forEach((item) => {
        if (selectedIds.includes(String(item.id))) {
          item.value = map[item.id]
        }
      })
    }
  }
)

const listData = ref([])

const loadCheckedItems = () => {
  // selectedRowKeys.value = props.selectedItems
  if (
    Array.isArray(props.selectedItems) &&
    props.selectedItems.length > 0 &&
    parameterList.value.length > 0
  ) {
    props.selectedItems.forEach((id) => {
      const item = parameterList.value.find((param) => {
        return String(param.id) === id
      })

      if (item) {
        nextTick(() => {
          tableRef.value.toggleRowSelection(item, true)
        })
      }
    })
  }
}

const valueTypes = ref([])
const valueTypeMap = ref({})
// const cardTypeMap = ref({})
// const cardTypes = ref([])
const initialParameterList = ref([])
const initialListData = async (type) => {
  parameterList.value = await store.dispatch('param/getAllParameters', type)
  parameterList.value = parameterList.value.map((item) => ({
    ...item,
    isEditable: false
  }))
  initialParameterList.value = cloneDeep(parameterList.value)
  listData.value = [...parameterList.value] // 使用扩展运算符进行浅拷贝
}
onMounted(async () => {
  await initialListData(props.templateType)
  if (!props.isViewPage) loadCheckedItems()
  valueTypes.value = await useCommonParam('value_type')
  valueTypes.value.forEach((item) => {
    valueTypeMap.value[item.value] = item.key
  })

  // cardTypes.value = await useCommonParam('card_type')
  // cardTypes.value.forEach((item) => {
  //   cardTypeMap.value[item.value] = item.key
  // })
})

watch(
  () => props.templateType,
  async (newVal) => {
    if (newVal === '') parameterList.value = []
    if (newVal) {
      await initialListData(newVal)
      if (!props.isViewPage) loadCheckedItems()
    }
  }
)
const selectedListData = ref([])
watch(
  () => listData.value,
  (newVal) => {
    if (newVal) {
      console.log(newVal)
      selectedListData.value = newVal.filter((item) => {
        if (item.checked) {
          return item.key
        }
      })
      console.log(selectedListData.value)
    }
  },
  { deep: true }
)

const handleResetParameterList = async () => {
  parameterList.value = await store.dispatch(
    'param/getAllParameters',
    props.templateType
  )
  parameterList.value = parameterList.value.map((item) => ({
    ...item,
    isEditable: false
  }))
}
const handleResetAndInitCheckedParameters = async () => {
  parameterList.value = await store.dispatch(
    'param/getAllParameters',
    props.templateType
  )
  parameterList.value = parameterList.value.map((item) => ({
    ...item,
    isEditable: false
  }))
  loadCheckedItems()
}
defineExpose({
  selectedRowItems,
  getValidateRes,
  selectedListData,
  clearSelection,
  validateAllForms,
  handleResetParameterList,
  handleResetAndInitCheckedParameters
})
</script>

<style>
.tb-container2 .el-table .el-table__cell {
  padding: 0 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
